

import React, { useEffect, useState } from 'react';

const Demobet = () => {
    const [forms, setForms] = useState([]);
    const [showForms, setShowForms] = useState(false);

    const addForm = () => {
        setForms([...forms, { id: forms.length + 1 }]);
        setShowForms(true);  
    };

    const handleSubmit = async (event, formIndex) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        try {
            const response = await fetch('https://kycptr.ajking.in/dummyBattle', {
                method: 'post',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();

            alert(`form Submit successful  `);

            console.log('Result:', result);
    
 
            window.location.reload();
        } catch (error) {
            alert(`There was a problem with the upload for form ${formIndex + 1}.`);
            console.error('Error:', error);
        }
    };
    
    const [data, setData] = useState([]);
    const Submit = async () => {
        try {
            const response = await fetch('https://kycptr.ajking.in/dummyBattle', {
                method: 'get',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.warn(result); 

            
            if (result.status === 'success' && Array.isArray(result.data)) {
                setData(result.data); 
            } else {
                console.error('Unexpected data format or status');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

   
    useEffect(() => {
        Submit();
    }, []); 


    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://kycptr.ajking.in/dummyBattle/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const updatedData = data.filter(item => item._id !== id);
            setData(updatedData);

            alert('Item deleted successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('There was a problem deleting the item.');
        }
    };

    return (
        <div className='card'>
            <div className='card-header d-flex justify-content-between'>
                <div className='text-dark mb-0 '>Add Demo Bet</div>
                <div
                    className='card-title text-light mb-0 btn btn-primary'
                    onClick={addForm}
                    style={{ cursor: 'pointer' }}
                >
                    Add Demo Bet
                </div>
            </div>
            <div>
            {showForms && (
                <div className='card-body bg-white p-2'>
                    {forms.map((form, index) => (
                        <form key={form.id} onSubmit={(event) => handleSubmit(event, index)}>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor={`createrName${form.id}`}>Creator Name</label>
                                        <input
                                            type="text"
                                            id={`createrName${form.id}`}
                                            name="createrName"
                                            required
                                            className='form-control'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor={`createrImage${form.id}`}>Creator Image</label>
                                        <input
                                            type="file"
                                            id={`createrImage${form.id}`}
                                            name="createrImage"
                                            accept="image/*"
                                            required
                                            className='form-control'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor={`amount${form.id}`}>Amount</label>
                                        <input
                                            type="number"
                                            id={`amount${form.id}`}
                                            name="amount"
                                            required
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                               
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor={`accepterName${form.id}`}>Accepter Name</label>
                                        <input
                                            type="text"
                                            id={`accepterName${form.id}`}
                                            name="accepterName"
                                            required
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                               
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor={`accepterImage${form.id}`}>Accepter Image</label>
                                        <input
                                            type="file"
                                            id={`accepterImage${form.id}`}
                                            name="accepterImage"
                                            accept="image/*"
                                            required
                                            className='form-control'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label htmlFor={`price${form.id}`}>  Win Price</label>
                                        <input
                                            type="text"
                                            id={`price${form.id}`}
                                            name="price"
                                            required
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" value="submit" />
                        </form>
                    ))}
                </div>
            )}
    <div className="table-responsive">        
    <table className="table table-striped table-bordered-top">
      <thead className="thead-dark">
        <tr>
          <th>#</th>
          <th>Creator Name</th>
          <th>Creator Image</th>
          <th>Accepter Name</th>
          <th>Accepter Image</th>
          <th>Amount</th>
          <th>Win Price</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item ,index)=> (
          <tr key={index}>
            <td>{index+1}</td>
            <td>{item.createrName}</td>
            <td>
              {item.createrImage ? (
                <img
                  src={`https://kycptr.ajking.in/${item.createrImage}`}
                  alt="Creator"
                  className="img-thumbnail"
                  style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
              ) : (
                <span>No Image</span>
              )}
            </td>
            <td>{item.accepterName}</td>
            <td>
              {item.accepterImage ? (
                <img
                  src={`https://kycptr.ajking.in/${item.accepterImage}`}
                  alt="Accepter"
                  className="img-thumbnail"
                  style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
              ) : (
                <span>No Image</span>
              )}
            </td>
            <td>{item.amount}</td>
            <td>{item.price}</td>
            <td>
              <button
                onClick={() => handleDelete(item._id)}
                className="btn btn-danger btn-sm"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
            </div>
        </div>
    );
};

export default Demobet;
