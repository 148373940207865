import React, { useState, useEffect } from "react";
import axios from "axios";

export const QRSettings = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;
  const [selectedFile, setSelectedFile] = useState(null);
  const [qrSetting, setQRSetting] = useState(null);

  useEffect(() => {
    const fetchQRSetting = async () => {
      try {
        const response = await axios.get(`${baseUrl}User/qrSettings`);
        if (response.data.status === 'success' && response.data.qrSettings.length > 0) {
          setQRSetting(response.data.qrSettings[0]); 
        } else {
          setQRSetting(null);
        }
      } catch (error) {
        console.error("Error fetching QR setting:", error);
      }
    };

    fetchQRSetting();
  }, [baseUrl]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedFile) {
      alert("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append('qrCode', selectedFile);

    try {
      const response = await axios.post(`https://apifront.ajking.in/User/qrSettings`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.status === 'success') {
        setQRSetting({ qrCodeUrl: response.data.qrCodeUrl });
        alert("QR code uploaded successfully");
      } else {
        alert("QR code upload failed");
      }
    } catch (error) {
      console.error("Error uploading QR code:", error);
      alert("Error uploading QR code");
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-uppercase font-weight-bold mb-4">Upload QR Code</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <button type="submit" className="btn btn-dark w-100">
                  Upload
                </button>
              </div>
            </div>
          </form>
          <div className="mt-4">
            {qrSetting ? (
              <div className="mb-3">
                <img 
                  src={`https://apifront.ajking.in/public${qrSetting.qrCodeUrl}`} 
                  alt="QR Code" 
                  className="img-thumbnail" 
                />
              </div>
            ) : (
              <p>No QR code available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
